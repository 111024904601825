import React from 'react'
import { FormattedMessage } from 'react-intl'

import FlowImg from './assets/images/flow.inline.svg'
import FlowEnImg from './assets/images/flow-en.inline.svg'

import './styles.scss'

interface PSPValuesProps {
  lang: string;
}

const PSPValues: React.FC<PSPValuesProps> = ({lang}) => {
  return (
    <section className="psp__values-bg py-layout-4">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="text-primary text-center">
              <FormattedMessage id="psp.values.title" />
            </h2>
            <p className="text-primary text-center">
              <FormattedMessage id="psp.value.description" />
            </p>
          </div>
          <div className="col-md-12">
            {
              lang === 'es' ?
                <FlowImg className="psp__values-img" />
              :
                <FlowEnImg className="psp__values-img" />
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPValues