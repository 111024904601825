import React from 'react'
import { FormattedMessage } from 'react-intl'

import PhoneImg from './assets/images/phone.inline.svg'
import PhoneEnImg from './assets/images/phone-en.inline.svg'
import './styles.scss'

interface PSPPaymentsProps {
  lang: string
}

const PSPPayments: React.FC<PSPPaymentsProps> = ({lang}) => {
  return (
    <section className="psp__payments-bg py-layout-6">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12 ">
            <h2 className="text-white py-layout-2">
              <FormattedMessage id="psp.payments.title" />
            </h2>
            <div className="row">
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item1" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item2" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item3" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item4" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item5" />
                  </p>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="psp__payments-pills py-layout-1">
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.payments.item6" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {
              lang === 'es' ?
              <PhoneImg className="psp__payments-img" />
              :
              <PhoneEnImg className="psp__payments-img" />
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPPayments