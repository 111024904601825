import React from 'react'
import { FormattedMessage } from 'react-intl'

import MoneyImg from './assets/images/money.inline.svg'
import GlobeImg from './assets/images/globe.inline.svg'
import PriceImg from './assets/images/price.inline.svg'
import CheckImg from './assets/images/check.inline.svg'

import './styles.scss'

const PSPBenefits: React.FC = () => {
  return (
    <section className="psp__bg-benefits">
      <div className="container py-layout-4">
        <div className="row">
          <div className="col-md-12">
            <h2><FormattedMessage id="psp.benefits.title" /> </h2>
            <p><FormattedMessage id="psp.benefits.subtitle" /></p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6 col-12">
            <div className="card psp__card-benefits  px-layout-2">
              <CheckImg />
              <p>
                <FormattedMessage id="psp.cards.item1" />
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12">
            <div className="card psp__card-benefits  px-layout-2">
              <MoneyImg />
              <p>
                <FormattedMessage id="psp.cards.item2" />
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12">
            <div className="card psp__card-benefits  px-layout-2">
              <GlobeImg />
              <p>
                <FormattedMessage id="psp.cards.item3" />
              </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-12">
            <div className="card psp__card-benefits  px-layout-2">
              <PriceImg />
              <p>
                <FormattedMessage id="psp.cards.item4" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPBenefits