import React from 'react'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import './styles.scss'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

interface PSPHeroProps {
  lang: string
}

const PSPHero: React.FC<PSPHeroProps> = ({lang}) => {
  return (
    <section className="hero bg-hero">
      <div className="container">
        <div className="row px-lg-layout-2">
          <div className="col-md-12">
            <div className="psp__containte-titles">
              <h1 className="psp__hero-title-top">
                <FormattedMessage id="psp.hero.title_top"/>
              </h1>
              <h2 className="text-white psp__hero-title">
                <FormattedMessage id="psp.hero.title" />
              </h2>
              <p className="text-white text-center">
                <FormattedMessage id="psp.hero.subtitle" />
              </p>
            </div>
          </div>
          <div className="col-md-12">
              <div className="psp-video">
                <iframe width="1038" height="584" src="https://www.youtube.com/embed/PcmP2vh1SKk?rel=0" title="Kushki  - Adquirente regional no bancario" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <LocalizedLink to="/industries/form" state={{page: 'send'}}>
            <button className="btn--green my-lg-layout-3" >
              <FormattedMessage id="psp.hero.button" />
            </button>
          </LocalizedLink>
        </div>
      </div>
    </section>
  )
}

export default PSPHero