import React from 'react'
import { FormattedMessage } from 'react-intl'
import ArrowLink from '../../ArrowLink/ArrowLink'
import CardIcon from './assets/images/card.inline.svg'
import CodeIcon from './assets/images/code.inline.svg'
import BookIcon from './assets/images/book-open.inline.svg'
import LinkIcon from './assets/images/link.inline.svg'
import RefreshIcon from './assets/images/refresh.inline.svg'
import SendIcon from './assets/images/send.inline.svg'
import ServerIcon from './assets/images/server.inline.svg'
import CartIcon from './assets/images/cart.inline.svg'

import './styles.scss'
import { Send } from '@kushki/suka-react-system-icons'

const CashLinks: React.FC = () => {
  return (
    <section className="container cash_links my-layout-3">
      <div className="row">
        <div className="col-12 col-md-3">
          <div className="cash_links-title-primary ">
            <h3 className="text-left text-primary">
              <FormattedMessage id="cash_landing.links.payment_method" />
            </h3>
          </div>
          <div>
            <ul className="cash_links-list">
              <li className="my-layout-1 text-primary">
                <ArrowLink color="primary" className="text-primary" to="/payments/card">
                  <CardIcon className="cash_links-list_icon" />
                  <FormattedMessage id="cash_landing.links.card" />
                </ArrowLink>
              </li>
              <li>
                <ArrowLink color="primary" className="text-primary" to="/payments/transfer">
                  <RefreshIcon className="cash_links-list_icon" />
                  <FormattedMessage id="cash_landing.links.transfer" />
                </ArrowLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div className="cash_links-title-b  text-primary-light">
            <h3 className="text-left">
              <FormattedMessage id="cash_landing.links.integrations" />
            </h3>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 order-1">
              <ul className="cash_links-list">
                <li className="my-layout-1">
                  <ArrowLink className="text-primary-light"  color="primary-light" to="/features/link-de-pago-smartlink">
                    <SendIcon className="cash_links-list_icon" />
                    <FormattedMessage id="cash_landing.links.smartlinks" />
                  </ArrowLink>
                </li>
                {/* <li className="my-layout-1">
                  <ArrowLink className="text-primary-light" color="primary-light" to="/pagos-presenciales">
                    <LinkIcon className="cash_links-list_icon" />
                    <FormattedMessage id="cash_landing.links.plugins" />
                  </ArrowLink>
                </li> */}
                <li className="my-layout-1">
                  <ArrowLink className="text-primary-light" color="primary-light" to="/features/cajita">
                    <ServerIcon className="cash_links-list_icon" />
                    <FormattedMessage id="cash_landing.links.kajita" />
                  </ArrowLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 order-2">
            <ul className="cash_links-list">
              <li className="my-layout-1">
                <ArrowLink className="text-primary-light" color="primary-light" to="/features/buttonPay">
                  <CartIcon className="cash_links-list_icon" />
                  <FormattedMessage id="cash_landing.links.pay_button" />
                </ArrowLink>
              </li>
              <li className="my-layout-1">
                <ArrowLink className="text-primary-light" color="primary-light" to="/products">
                  <CodeIcon className="cash_links-list_icon" />
                  <FormattedMessage id="cash_landing.links.api" />
                </ArrowLink>
              </li>
              {/* <li className="my-layout-1">
                <ArrowLink className="text-primary-light" color="primary-light" to="/features/link-de-pago-smartlink">
                  <BookIcon className="cash_links-list_icon" />
                  <FormattedMessage id="cash_landing.links.libraries" />
                </ArrowLink>
              </li> */}
            </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CashLinks