import React from 'react'
import { FormattedMessage } from 'react-intl'
import CashIcon from './assets/images/cash.inline.svg'
import TransferIcon from './assets/images/transfer.inline.svg'
import CardIcon from './assets/images/card.inline.svg'
import BankIcon from './assets/images/bank.inline.svg'
import PointIcon from './assets/images/point.inline.svg'
import PaymentIcon  from './assets/images/payment.inline.svg'
import TotalIcon from './assets/images/total.inline.svg'
import LockIcon from './assets/images/lock.inline.svg'
import KeyIcon from './assets/images/key.inline.svg'
import ShieldIcon from './assets/images/shield.inline.svg'
import './styles.scss'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

const PSPServices: React.FC = () => {
  return (
    <section className="container py-layout-4">
      <div className="row d-flex justify-content-center">
        <h2 className="text-center text-primary">
          <FormattedMessage id="psp.services.title" />
        </h2>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="card psp__card-services p-layout-1">
            <h5 className="text-center text-white ">
              <FormattedMessage id="psp.service.card1.title" />
            </h5>
            <div className="card-body psp__card-body">
              <p className="psp__card-body_title text-center text-white">
                <FormattedMessage id="psp.service.card1.subtitle" />
              </p>
              <div className="row justify-content-center">
                <div className="col-4 d-flex flex-column align-items-center jsutify-content-center">
                  <CashIcon />
                  <p className="text-white">
                    <FormattedMessage id="psp.services.card.cash" />
                  </p>
                </div>
                <div className="col-4 d-flex flex-column align-items-center jsutify-content-center">
                  <TransferIcon />
                  <p className="text-white">
                    <FormattedMessage id="psp.services.card.transfer" />
                  </p>
                </div>
                <div className="col-4 d-flex flex-column align-items-center jsutify-content-center">
                  <CardIcon />
                  <p className="text-white">
                    <FormattedMessage id="psp.services.card.card" />
                  </p>
                </div>
              </div>
              <p className="text-center text-white">
                <FormattedMessage id="psp.service.card1.subtitle2" />
              </p>
              <div className="row justify-content-center">
                <div className="col-6 d-flex flex-column align-items-center jsutify-content-center">
                  <BankIcon />
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.services.card.calendar" />
                  </p>
                </div>
                <div className="col-6 d-flex flex-column align-items-center jsutify-content-center">
                  <PointIcon />
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.services.card.demand" />
                  </p>
                </div>
                <div className="col-6 d-flex flex-column align-items-center jsutify-content-center">
                  <PaymentIcon />
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.services.card.preautorized" />
                  </p>
                </div>
                <div className="col-6 d-flex flex-column align-items-center jsutify-content-center">
                  <TotalIcon />
                  <p className="text-white text-center">
                    <FormattedMessage id="psp.services.card.refund" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card psp__card-services p-layout-1">
            <h5 className="text-center text-white ">
              <FormattedMessage id="psp.service.card2.title" />
            </h5>
            <div className="card-body psp__card-body-middle ">
              <div className="col-12 d-flex flex-column align-items-center jsutify-content-center">
                <CashIcon className="psp__card-services-icon_cash" />
                <p className="text-white">
                  <FormattedMessage id="psp.services.card.cash" />
                </p>
              </div>
              <div className="col-12 d-flex flex-column align-items-center jsutify-content-center">
                <TransferIcon className="psp__card-services-icon_cash" />
                <p className="text-white">
                  <FormattedMessage id="psp.services.card.transfer" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card psp__card-services p-layout-1">
            <h5 className="text-center text-white ">
              <FormattedMessage id="psp.service.card3.title" />
            </h5>
            <div className="card-body psp__card-body-middle ">
              <div className="col-12 d-flex flex-column align-items-center jsutify-content-center">
                <LockIcon className="psp__card-services-icon_cash" />
                <p className="text-white">
                  <FormattedMessage id="psp.services.card.otp" />
                </p>
              </div>
              <div className="col-12 d-flex flex-column align-items-center jsutify-content-center">
                <KeyIcon className="psp__card-services-icon_cash" />
                <p className="text-white">
                  <FormattedMessage id="psp.services.card.3ds" />
                </p>
              </div>
              <div className="col-12 d-flex flex-column align-items-center jsutify-content-center">
                <ShieldIcon className="psp__card-services-icon_cash" />
                <p className="text-white">
                  <FormattedMessage id="psp.services.card.scoring" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-layout-4 justify-content-center">
        <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
          <h5 className="text-primary text-center">
            <FormattedMessage id="psp.services.description" />
          </h5>
          <div className="" >
            <LocalizedLink to="/industries/form" state={{page: 'send'}}>
              <button className="btn--green my-lg-layout-3" >
                <FormattedMessage id="psp.hero.button" />
              </button>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPServices