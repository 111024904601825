import React from 'react'
import { FormattedMessage } from 'react-intl'

import Lottie from 'react-lottie';
import animationData from './assets/json/benefits.json'
import GalaxyImg from './assets/images/universe.inline.svg'

import CheckImg from './assets/images/check.inline.svg'
import MetricImg from './assets/images/metric.inline.svg'
import AtomsImg from './assets/images/atom.inline.svg'
import PointerImg from './assets/images/direct.inline.svg'
import DirectionImg from './assets/images/direction.inline.svg'
import PencilImg from './assets/images/pencil.inline.svg'
import LikeImg from './assets/images/like.inline.svg'

import './styles.scss'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

const PSPBenefitsList = () => {
  return (
    <section className="container py-layout-4">
        <h2 className="text-center text-primary">
          <FormattedMessage id="psp.benefits_list.title" />
        </h2>
      <div className="row">
        <div className="col-md-6">
          <div>
            <GalaxyImg className="psp__benefitsList-image" />
            {/* <Lottie
              options={{
                animationData,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            /> */}
          </div>
        </div>
        <div className="col-md-6 d-flex jsutify-content-center">
          <div className="psp__benefitList-list">
            <ul className="psp__benefitList-list-contain">
              <li><CheckImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item1" /></li>
              <li><MetricImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item2" /></li>
              <li><AtomsImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item3" /></li>
              <li><DirectionImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item4" /></li>
              <li><PointerImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item5" /></li>
              <li><PencilImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item6" /></li>
              <li><LikeImg className="mx-2" /><FormattedMessage id="psp.benefits_list.list.item7" /></li>
            </ul>
            <LocalizedLink to="/industries/form" state={{page: 'download'}}>
              <button className="btn--basic">
                <FormattedMessage id="psp.benefits_list.button" />
              </button>
            </LocalizedLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPBenefitsList