import React from 'react'

import './styles.scss'
import { FormattedMessage } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import LocalizedLink from '../../LocalizedLink/LocalizedLink'

interface PSPBannerProps {
  lang: string
}

const PSPBanner: React.FC<PSPBannerProps> = ({lang}) => {
  return (
    <section className={` ${lang === 'es' ? 'psp__bg-banner' : 'psp__bg-banner-en'} py-layout-7`} >
      <div className="container pt-layout-6">
        <div className="row">
          <div className="col-md-6 col-12">
            <h2>
              <FormattedMessage id="psp.banner.title" />
            </h2>
            <h3>
              <FormattedMessage id="psp.banner.description" />
            </h3>
            <LocalizedLink to="/industries/form" state={{page: 'send'}}>
              <button className="btn--green my-lg-layout-3" >
                <FormattedMessage
                  id="index.hero.button"
                  values={defaultFormatValues}
                  />
              </button>
            </LocalizedLink>
            <p>
              <FormattedMessage id="psp.banner.text" />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PSPBanner