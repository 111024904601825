import React, {useEffect} from 'react'
import PSPHero from '../../components/PSP/PSPHero/PSPHero'
import PSPBenefits from '../../components/PSP/PSPBenefits/PSPBenefits'
import IndexLayout from '../../layouts'
import { LocaleTypes } from '../../data/locales';
import PSPBanner from '../../components/PSP/PSPBanner/PSPBanner';
import PSPBenefitsList from '../../components/PSP/PSPBenefitsList/PSPBenefitsList';
import PSPValues from '../../components/PSP/PSPValues/PSPValues';
import PSPServices from '../../components/PSP/PSPServices/PSPServices';
import PSPPayments from '../../components/PSP/PSPPayments/PSPPayments';
import Footer from '../../components/Footer/Footer';
import CashLinks from '../../components/CashLanding/CashLinks/CashLinks';
import SEO from '../../components/SEO/SEO';

interface PSPProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const psp: React.FC<PSPProps> = ({pathContext, location}) => {
  
  useEffect(() => {
    // Start of crazyegg code
    if( undefined !== window ){
      (function(w) {
        const s = document.createElement(`script`);
        s.src = `//script.crazyegg.com/pages/scripts/0120/7444.js`;
        s.async = true;
        const e = document.getElementsByTagName(`script`)[0];
        e.parentNode?.insertBefore(s, e);
      })(window);
    }
  // End of crazyegg code
  })

  const content = () => (
    <>
      <SEO titleId="psp.hero.title_top" />
      
      <PSPHero lang={pathContext.localeCode} />

      <PSPBenefits />

      <PSPBanner lang={pathContext.localeCode} />

      <PSPBenefitsList />

      <PSPValues lang={pathContext.localeCode} />

      <PSPServices />

      <PSPPayments lang={pathContext.localeCode} />

      <CashLinks />

      <Footer theme="primary" />
    </>
  )
  return (
    <IndexLayout navbarTheme="light" locale={pathContext.localeCode} location={location} render={content} />
  )

}

export default psp